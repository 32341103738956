import React from "react"
import { num, numFormat } from "../utils"
import clsx from "clsx"

const TableHandling = ({ itemName, subtotalCost, handling, className }) => {
  subtotalCost = num(subtotalCost)
  const handlingCost = (subtotalCost * num(handling)) / 100
  const totalCost = handlingCost + subtotalCost

  return (
    <table
      id="table-handling"
      className={clsx("table-headleft", "table-handling", className)}
    >
      <tbody>
        <tr>
          <th>Subtotal {itemName}</th>
          <td>{numFormat(subtotalCost)}</td>
        </tr>
        <tr>
          <th>Handling ({numFormat(handling)}%)</th>
          <td>{numFormat(handlingCost)}</td>
        </tr>
        <tr>
          <th>Total material</th>
          <td>{numFormat(totalCost)}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default TableHandling
