import clsx from "clsx"
import React from "react"

function ApprovalTable({
  tableClasses,
  currentCheck,
  currentApprove,
  data,
  approvedImage,
}) {
  return (
    <div style={{ marginBottom: "3mm", marginTop: "3mm" }}>
      <table className={clsx(tableClasses.approvalTable, "fontSize")}>
        <thead>
          <tr>
            <th>Prepared By</th>
            <th>Checked By</th>
            <th>Approved By</th>
          </tr>
        </thead>
        <tbody style={{ marginTop: "4em", marginBottom: "4em" }}>
          <tr>
            <td>
              <br />
              <br />
            </td>
            <td>
              {currentCheck?.node?.judgement === "ACCEPT" && approvedImage}
            </td>
            <td>
              {currentApprove?.node?.judgement === "ACCEPT" && approvedImage}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>{data.prepared?.name ?? ""}</th>
            <th>{data.checked?.name ?? ""}</th>
            <th>{data.approved?.name ?? ""}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default ApprovalTable
