import React from "react"
import clsx from "clsx"
import { numFormat } from "../utils"
import { MARGINS } from "../constants"

const MarginCell = ({ margin, totalCost }) => {
  return (
    <>
      <th>{margin}%</th>
      <td>{numFormat((1 + margin / 100) * totalCost)}</td>
    </>
  )
}

function TotalCostSection({
  classes,
  totalProcess,
  rateInValue,
  rateOutValue,
  totalMaterial,
  totalAdditional,
  totalOutsourcing,
  totalCost,
  margin,
  printTarget,
  rateInCurr,
  rateOutCurr,
  totalStandardPart = 0,
  totalSparePart = 0,
  processPercentage = 0,
  materialPercentage = 0,
  outsourcingPercentage = 0,
  additionalPercentage = 0,
  standardPartPercentage = 0,
  sparePartPercentage = 0,
  marginCurrencies = [],
}) {
  return (
    <>
      <div id="total-cost-table" style={{ display: "flex" }}>
        <table
          cellSpacing={0}
          className={clsx(classes.totalCostsTable, "fontSize")}
        >
          <thead>
            <tr>
              <th></th>
              <th>IDR</th>
              <th>{rateInCurr.code}</th>
              <th>{rateOutCurr.code}</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Total Material Costs</th>
              <td>{numFormat(totalMaterial)}</td>
              <td>{numFormat(totalMaterial / rateInValue)}</td>
              <td>{numFormat(totalMaterial / rateOutValue)}</td>
              <td>
                {Number.isNaN(materialPercentage) ? 0 : materialPercentage}%
              </td>
            </tr>
            <tr>
              <th>Additional Costs</th>
              <td>{numFormat(totalAdditional)}</td>
              <td>{numFormat(totalAdditional / rateInValue)}</td>
              <td>{numFormat(totalAdditional / rateOutValue)}</td>
              <td>
                {Number.isNaN(additionalPercentage) ? 0 : additionalPercentage}%
              </td>
            </tr>
            <tr>
              <th>Standard Part Costs</th>
              <td>{numFormat(totalStandardPart)}</td>
              <td>{numFormat(totalStandardPart / rateInValue)}</td>
              <td>{numFormat(totalStandardPart / rateOutValue)}</td>
              <td>
                {Number.isNaN(standardPartPercentage)
                  ? 0
                  : standardPartPercentage}
                %
              </td>
            </tr>
            <tr>
              <th>Spare Part (Cutting Tool) Costs</th>
              <td>{numFormat(totalSparePart)}</td>
              <td>{numFormat(totalSparePart / rateInValue)}</td>
              <td>{numFormat(totalSparePart / rateOutValue)}</td>
              <td>
                {Number.isNaN(sparePartPercentage) ? 0 : sparePartPercentage}%
              </td>
            </tr>
            <tr>
              <th>Outsourcing Costs</th>
              <td>{numFormat(totalOutsourcing)}</td>
              <td>{numFormat(totalOutsourcing / rateInValue)}</td>
              <td>{numFormat(totalOutsourcing / rateOutValue)}</td>
              <td>
                {Number.isNaN(outsourcingPercentage)
                  ? 0
                  : outsourcingPercentage}
                %
              </td>
            </tr>
            <tr>
              <th>Total Process Costs</th>
              <td>{numFormat(totalProcess)}</td>
              <td>{numFormat(totalProcess / rateInValue)}</td>
              <td>{numFormat(totalProcess / rateOutValue)}</td>
              <td>
                {Number.isNaN(processPercentage) ? 0 : processPercentage}%
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <td>{numFormat(totalCost)}</td>
              <td>{numFormat(totalCost / rateInValue)}</td>
              <td>{numFormat(totalCost / rateOutValue)}</td>
              <td>
                {Number.isNaN(
                  materialPercentage +
                    processPercentage +
                    additionalPercentage +
                    outsourcingPercentage +
                    standardPartPercentage +
                    sparePartPercentage
                )
                  ? 0
                  : materialPercentage +
                    processPercentage +
                    additionalPercentage +
                    outsourcingPercentage +
                    standardPartPercentage +
                    sparePartPercentage}
                %
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: "1rem", fontWeight: "bold" }}>
                MARGINS
              </td>
              {marginCurrencies.map((mc) => {
                return (
                  <td
                    style={{ paddingTop: "1rem", fontWeight: "bold" }}
                    key={mc.code}
                  >
                    {mc.code}
                  </td>
                )
              })}
            </tr>
            {MARGINS.map((margin) => {
              return (
                <tr key={margin}>
                  <td style={{ fontWeight: "bold" }}>{margin}%</td>
                  {marginCurrencies.map((mc) => {
                    return (
                      <td>
                        {numFormat((1 + margin / 100) * (totalCost / mc.rate))}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tfoot>
        </table>
        {printTarget !== "customer" ? (
          <div style={{ position: "relative", marginLeft: "3mm" }}>
            <p>Approved margin</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={classes.approvedBox}
            >
              <strong>{numFormat(margin)}</strong>
            </div>
            <p
              style={{
                position: "absolute",
                left: "calc(100% + 4pt)",
                top: "calc(25% - 7pt)",
                fontSize: "13pt",
              }}
            >
              %
            </p>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default TotalCostSection
