/* eslint-disable react/prop-types */
import BaseTableNew from "components/common/BaseTableNew"
import React, { useMemo } from "react"
import { formatDate } from "utils/form-data"
import { ROUTE_INVOICE_ROOT } from "constants/routeConstants"
import { useHistory } from "react-router-dom"
import { Typography } from "@material-ui/core"
import useDatatablesFetchHelper from "utils/datatables/useDatatablesFetchHelper"
import ApiCustomer from "services/api-customer"
import FilterDatatable from "components/common/FilterDatatable"
import InvoiceListFilter from "./InvoiceListFilter"

const fetchCustomerData = async (searchText) =>
  (await ApiCustomer.get({ searchText, limit: 10, fromColumns: ["name"] })).data

function InvoiceListAccordionItem({ year, fetchInvoiceData }) {
  const history = useHistory()
  const [filter, setFilter] = React.useState({})
  const [params, setParams] = React.useState({})

  const getParams = React.useCallback(() => {
    return { ...params, ...filter, year }
  }, [filter, year, params])
  const { options: helperOptions, data, loading } = useDatatablesFetchHelper({
    fetchData: fetchInvoiceData,
    getParams,
  })

  const goToItemAtIndex = (_, { dataIndex }) => {
    const item = data[dataIndex]
    history.push(`${ROUTE_INVOICE_ROOT}/${item.idInvoice}`)
  }

  const options = {
    ...helperOptions,
    onRowClick: goToItemAtIndex,
  }

  const column = useMemo(
    () => [
      {
        name: "invoiceNumber",
        label: "Invoice Number",
      },
      {
        name: "date",
        label: "Date",
        options: {
          searchable: false,
        },
      },
      {
        name: "invoiceType",
        label: "Type",
      },
      {
        name: "customer",
        label: "Customer Name",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            return item?.customer?.name
          },
          searchable: false,
        },
      },
      {
        name: "description",
        label: "Description",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            const { productDescriptions } = item
            const arr = productDescriptions.map((pd) => pd.description)
            return <p style={{ width: "250px" }}>{arr.join(",")}</p>
          },
          searchable: false,
          sort: false,
        },
      },
      {
        name: "mouldCode",
        label: "Mould Code",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            const { productDescriptions } = item
            const arr = Array.from(
              new Set(
                productDescriptions.map((desc) => {
                  const { slsEstimate } = desc
                  if (slsEstimate) {
                    return Array.from(
                      new Set(
                        slsEstimate.relatedWorkOrderEstimate.edges.map(
                          (edge) => edge.node.mouldCode
                        )
                      )
                    ).join(", ")
                  }
                  return desc?.productDetail?.mouldCode || ""
                })
              )
            )
            return <p style={{ width: "250px" }}>{arr.join(",")}</p>
          },
          searchable: false,
        },
      },
      {
        name: "poNumber",
        label: "PO. Number",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            const { productDescriptions = [] } = item
            const arr = []
            productDescriptions.forEach((pd) => {
              const source =
                pd?.productDetail?.salesEstimate || pd?.salesEstimate

              arr.push(source?.slsPurchaseOrder?.poNumber || "")
            })
            return arr.filter((a) => a).join(", ")
          },
        },
      },
      {
        name: "dnNumber",
        label: "DN. Number",
        options: {
          searchable: false,
          customBodyRenderLite: (index) => {
            const item = data[index]
            const { productDescriptions = [] } = item
            const arr = productDescriptions
              .filter((pd) => pd.productDetail)
              .map((pd) => {
                const {
                  productDetail: { deliveryNote },
                } = pd
                return deliveryNote.dnNumber
              })

            const unique = Array.from(new Set(arr))
            return unique.map((u) => <Typography key={u}>{u}</Typography>)
          },
        },
      },
      {
        name: "paymentStatus",
        label: "Payment Status",
        options: {
          customBodyRenderLite: (index) => {
            const item = data[index]
            const { paidDate } = item
            const showPaidDate = !!paidDate

            return (
              <div style={{ textAlign: "center" }}>
                <p style={{ margin: 0 }}>{item.paymentStatus.toUpperCase()}</p>
                {showPaidDate ? (
                  <p style={{ margin: 0, fontSize: 12 }}>
                    {formatDate(paidDate, true)}
                  </p>
                ) : null}
              </div>
            )
          },
        },
      },
    ],
    [data]
  )
  return (
    <div>
      <InvoiceListFilter onParamsChange={(val) => setFilter(val)} />

      <FilterDatatable
        columns={[
          { key: "invoiceNumber", label: "Invoice Number", type: "text" },
          { key: "date", label: "Date", type: "date" },
          {
            key: "invoiceType",
            label: "Type",
            type: "list",
            options: [
              "INVOICE",
              "INVOICE EXPORT",
              "DEBIT NOTE",
              "DOWN PAYMENT",
            ],
            getOptionLabel: (opt) => opt,
            getOptionSelected: (opt, val) => opt === val,
            getOptionValue: (opt) => opt,
          },
          {
            key: "customerId",
            type: "list",
            fetchData: fetchCustomerData,
            getOptionSelected: (opt, val) => opt.idCust === val?.idCust,
            getOptionLabel: (opt) => opt.name,
            getOptionValue: (opt) => opt?.idCust,
            label: "Customer Name",
          },
          { key: "description", label: "Description", type: "text" },
          { key: "mouldCode", label: "Mould Code", type: "text" },
          { key: "poNumber", label: "PO. Number", type: "text" },
          { key: "dnNumber", label: "DN. Number", type: "text" },
          {
            key: "paymentStatus",
            label: "Payment Status",
            type: "list",
            options: ["PAID", "UNPAID"],
            getOptionLabel: (opt) => opt,
            getOptionValue: (opt) => opt?.toLowerCase(),
            getOptionSelected: (opt, val) => opt === val,
          },
        ]}
        onChange={setParams}
      />
      <BaseTableNew
        title={`Invoice ${year}`}
        data={data}
        loading={loading}
        columns={column}
        options={options}
      />
    </div>
  )
}

export default InvoiceListAccordionItem
