import merge from "lodash/merge"
import cloneDeep from "lodash/cloneDeep"
import has from "lodash/has"
import moment from "moment"
import "moment-precise-range-plugin"

/**
 * Purges top level fields of initial form data. Any fields which
 * aren't in baseBlankForm will be deleted.
 * @param {*} initialData
 * @param {*} baseBlankForm
 */

export const purgeInitialFormData = (initialData, baseBlankForm) => {
  const blankForm = cloneDeep(baseBlankForm)
  const formData = merge(cloneDeep(blankForm), cloneDeep(initialData))

  Object.keys(formData).forEach((key) => {
    if (!has(blankForm, key)) {
      delete formData[key]
    }
  })

  return formData
}

export const monthDiff = (dateFrom, dateTo) => {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  )
}

export function getTotalDayOfMonth(str) {
  const [year, month] = str.split("-")
  const date = new Date(year, month, 0)

  return date.getDate()
}

export const formatMonth = (dateString) => {
  const isDate = Object.prototype.toString.call(dateString) === "[object Date]"
  const date = isDate ? dateString : new Date(dateString)

  if (dateString === null) {
    return ""
  }

  if (Number.isNaN(date.getTime())) {
    return ""
  }

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
  const [
    { value: month },
    ,
    // eslint-disable-next-line no-unused-vars
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date)
  return `${month}-${year}`
}

export const formatDate = (dateString, short = false) => {
  const isDate = Object.prototype.toString.call(dateString) === "[object Date]"
  const date = isDate ? dateString : new Date(dateString)

  if (dateString === null) {
    return ""
  }

  if (Number.isNaN(date.getTime())) {
    return ""
  }

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: short ? "short" : "long",
    day: "numeric",
  })
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date)
  return `${day}-${month}-${year}`
}

export const roundToStep = (number, step = 1) => {
  const roundingNumber = 1 / parseFloat(step)
  return Math.round((number + Number.EPSILON) * roundingNumber) / roundingNumber
}

export const todayAsString = () => {
  return new Date().toISOString().slice(0, 10)
}

export const dateDifference = (fromDate, toDate = "") => {
  const fromDateObj = moment(fromDate)
  const toDateObj = toDate === "" ? moment() : moment(toDate)

  const diff = fromDateObj.preciseDiff(toDateObj, true)

  let yearMsg = ""
  let monthMsg = ""
  let dayMsg = ""
  if (Number.isNaN(diff.years)) {
    yearMsg = "0 year"
  } else {
    yearMsg = diff.years > 0 ? `${diff.years} years` : `${diff.years} year`
  }
  if (Number.isNaN(diff.months)) {
    monthMsg = "0 month"
  } else {
    monthMsg =
      diff.months > 0 ? `${diff.months} months` : `${diff.months} month`
  }
  if (Number.isNaN(diff.days)) {
    dayMsg = "0 day"
  } else {
    dayMsg = diff.days > 0 ? `${diff.days} days` : `${diff.days} day`
  }

  return `${yearMsg}, ${monthMsg}, ${dayMsg}`
}

export const fancyFormatTime = (minute, stepMinute = 0.01) => {
  if (Number.isNaN(minute)) {
    return ""
  }
  const absMinutes = Math.abs(minute)
  const sign = Math.sign(minute) < 0 ? "-" : ""
  const hour = Math.floor(absMinutes / 60)
  const minutes = roundToStep(absMinutes % 60, stepMinute)
  return `${sign}${hour}:${minutes}`
}

export const getCurrentDate = () => {
  // Create a date object
  const myDate = new Date()

  // Set the timezone you want (e.g., "America/New_York" or "UTC")
  const timeZone = "Asia/Jakarta"

  // Format the date with the desired timezone
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  }).format(myDate)

  const [m, d, y] = formattedDate.split(",")[0].split("/")

  return `${y}-${m}-${d}`
}

export const getFirstAndLastDay = () => {
  // First date of the month
  const firstDate = new Date()
  firstDate.setDate(1) // Set day to the first of the month

  // Last date of the month
  const lastDate = new Date()
  lastDate.setMonth(lastDate.getMonth() + 1) // Move to the next month
  lastDate.setDate(0) // Set to the last day of the previous month (current month)

  return { firstDate, lastDate }
}
