import TableLong from "components/common/print/TableLong"
import React from "react"
import clsx from "clsx"
import TableHandling from "./TableHandling"

function PrintCostTable({
  title,
  classes = {},
  data = [],
  columns = [],
  columnProps = {},
  handling = 0,
  subtotalCost = 0,
  printTarget = "",
  containerProps = {},
}) {
  return (
    <div style={{ pageBreakInside: "avoid" }}>
      <p className={clsx(classes.tableTitle, "fontSize")}>{title}</p>
      <div
        style={{
          maxWidth: "150mm",
          display: "flex",
          flexFlow: "column",
          alignItems: "flex-end",
          pageBreakInside: "avoid",
          ...(containerProps?.style ?? {}),
        }}
      >
        <TableLong
          className="fontSize"
          data={data}
          columns={columns}
          columnProps={columnProps}
          style={{ width: "100%" }}
        />
      </div>

      {printTarget !== "production" ? (
        <div
          style={{
            maxWidth: "150mm",
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
        >
          <TableHandling
            className="fontSize"
            itemName="additional"
            handling={handling}
            subtotalCost={subtotalCost}
          />
        </div>
      ) : null}
    </div>
  )
}

export default PrintCostTable
