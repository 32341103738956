import { cloneDeep } from "lodash"
import { useMemo } from "react"
import { num, numFormat } from "./utils"

export default function useEQPrintData({
  data,
  printTarget,
  marginMarkiplier,
  margin,
}) {
  /* Compute material costs and display */

  const materialData = useMemo(() => {
    let items = data.materialCosts || []
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items
      .map((estItem, i) => {
        const materialProcessCost = estItem?.process?.reduce((a, b) => {
          return a + (b.estimateProcess?.cost ?? 0) * b.time
        }, 0)
        const dim = (estItem?.dimension || "").toLowerCase().split("x")
        let weight =
          (num(dim[0] || 1) *
            num(dim[1] || 1) *
            num(dim[2] || 1) *
            num(estItem.material.materialType.density)) /
          1e6
        let materialPrice = estItem.unitPrice || estItem.material.unitPrice
        if (printTarget === "customer") {
          materialPrice *= 1 + (marginMarkiplier ?? margin) / 100
        }
        const total = weight * num(estItem.quantity) * num(materialPrice)

        const arr = [
          i + 1,
          estItem.description,
          estItem.material.materialCode,
          estItem.material?.materialType?.name || "-",
          estItem.dimension,
          weight,
          estItem.quantity,
          estItem.uom?.name,
          materialPrice,
          total,
          materialProcessCost,
          materialProcessCost + total,
        ]

        if (printTarget === "production") {
          arr.splice(8, 4)
        }

        return arr
      })
      .filter((item) => item)
    if (items.length < 1) return { items, totalCost }

    const TOTAL_INDEX = items[0].length - 3
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = [5, 6, 8, 9, 10, 11]

    if (printTarget === "production") {
      NUMBER_FIELDS.splice(2, 4)
    }
    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
    })
    return {
      items,
      totalCost,
    }
  }, [data.materialCosts, printTarget])

  /* Compute process costs and display */

  const processData = useMemo(() => {
    let items = data.processCosts || []
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items
      .map((estItem, i) => {
        if (estItem.time <= 0) return null
        let cost = estItem.cost
        if (printTarget === "customer") {
          cost *= 1 + (marginMarkiplier ?? margin) / 100
        }

        let time = estItem.time

        if (printTarget === "production" && data.productionHourReducer) {
          time -= time * (data.productionHourReducer / 100)
          time = Math.floor(time)
        }

        const arr = [
          i + 1,
          estItem.process?.name,
          time,
          estItem.materialProcessTime,
          estItem.uom?.name,
          num(estItem.time) + num(estItem.materialProcessTime),
          cost,
          num(estItem.time + +estItem.materialProcessTime) * num(cost),
        ]

        if (printTarget === "production") {
          arr.splice(5, 3)
        }

        return arr
      })
      .filter((item) => item)
    if (items.length < 1) return { items, totalCost }

    const TOTAL_INDEX = items[0].length - 1
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = printTarget === "production" ? [] : [2, 5, 3, 6, 7]

    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
    })

    return { items, totalCost }
  }, [data.processCosts, printTarget])

  /* Compute additional costs and display */

  const additionalData = useMemo(() => {
    let items = cloneDeep(data.additionalCosts || [])
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items.map((item, i) => {
      let cost = item.cost
      if (printTarget === "customer") {
        cost *= 1 + (marginMarkiplier ?? margin) / 100
      }
      const arr = [
        i + 1,
        item.process,
        item.quantity,
        item?.uom?.name,
        cost,
        item.handling,
        num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
      ]

      if (printTarget === "production") {
        arr.splice(4, 3)
      }
      return arr
    })

    const TOTAL_INDEX = items[0].length - 1
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
      if (printTarget !== "production") item[5] = `${item[5] || 0}%`
    })

    return { items, totalCost }
  }, [data.additionalCosts, printTarget])

  const standardPartData = useMemo(() => {
    let items = cloneDeep(data.standardPartCosts || [])
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items.map((item, i) => {
      let cost = item.cost
      if (printTarget === "customer") {
        cost *= 1 + (marginMarkiplier ?? margin) / 100
      }
      const arr = [
        i + 1,
        item.process,
        item.quantity,
        item?.uom?.name,
        cost,
        item.handling,
        num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
      ]

      if (printTarget === "production") {
        arr.splice(4, 3)
      }
      return arr
    })

    const TOTAL_INDEX = items[0].length - 1
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
      if (printTarget !== "production") item[5] = `${item[5] || 0}%`
    })

    return { items, totalCost }
  }, [data.standardPartCosts, printTarget])

  const sparePartData = useMemo(() => {
    let items = cloneDeep(data.sparePartCosts || [])
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items.map((item, i) => {
      let cost = item.cost
      if (printTarget === "customer") {
        cost *= 1 + (marginMarkiplier ?? margin) / 100
      }
      const arr = [
        i + 1,
        item.process,
        item.quantity,
        item?.uom?.name,
        cost,
        item.handling,
        num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
      ]

      if (printTarget === "production") {
        arr.splice(4, 3)
      }
      return arr
    })

    const TOTAL_INDEX = items[0].length - 1
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
      if (printTarget !== "production") item[5] = `${item[5] || 0}%`
    })

    return { items, totalCost }
  }, [data.sparePartCosts, printTarget])

  const outsourcingData = useMemo(() => {
    let items = cloneDeep(data.outsourcingCosts || [])
    let totalCost = 0
    if (items.length < 1) return { items, totalCost }

    items = items.map((item, i) => {
      let cost = item.cost
      if (printTarget === "customer") {
        cost *= 1 + (marginMarkiplier ?? margin) / 100
      }
      const arr = [
        i + 1,
        item.process,
        item.quantity,
        item?.uom?.name,
        cost,
        item.handling,
        num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
      ]

      if (printTarget === "production") {
        arr.splice(4, 3)
      }
      return arr
    })

    const TOTAL_INDEX = items[0].length - 1
    totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

    const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

    items.forEach((item) => {
      NUMBER_FIELDS.forEach((i) => {
        item[i] = numFormat(item[i])
      })
      if (printTarget !== "production") item[5] = `${item[5] || 0}%`
    })

    return { items, totalCost }
  }, [data.outsourcingCosts, printTarget])

  return {
    additionalData,
    materialData,
    outsourcingData,
    sparePartData,
    standardPartData,
    processData,
  }
}
