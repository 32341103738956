import reduce from "lodash/reduce"
import merge from "lodash/merge"
import isNil from "lodash/isNil"
import cloneDeep from "lodash/cloneDeep"
import map from "lodash/map"
import { numberFormat } from "utils/thousand-separator"
import * as yup from "yup"

export const estProcessListToDict = (estProcessCosts) =>
  reduce(
    estProcessCosts,
    (acc, item) => {
      const processId = (item || {}).processId
      if (!isNil(processId)) {
        acc[processId] = item
      }
      return acc
    },
    {}
  )

/**
 * Convert process cost object-like into arrays,
 * discarding 0 quantities.
 * @param {*} estProcessCosts
 */

export const estProcessDictToList = (estProcessCosts) =>
  map(estProcessCosts, (item, processId) =>
    parseFloat(item.time || 0) !== 0
      ? {
          ...item,
          time: parseFloat(item.time),
          processId: parseInt(processId),
        }
      : null
  ).filter((item) => item)

/**
 * Purges top level fields of initial form data. Any fields which
 * aren't in baseBlankForm will be deleted.
 * @param {*} initialData
 * @param {*} baseBlankForm
 */

export const purgeInitialFormData = (initialData, baseBlankForm) => {
  let blankForm = cloneDeep(baseBlankForm)
  const formData = merge(cloneDeep(blankForm), cloneDeep(initialData))

  Object.keys(formData).forEach((key) => {
    if (!blankForm.hasOwnProperty(key)) {
      delete formData[key]
    }
  })

  return formData
}

export const toDict = (list, idPropName = "id") => {
  if (typeof idPropName === "string") {
    return reduce(
      list,
      (acc, val) => {
        const key = val[idPropName]
        if (!isNil(key)) {
          acc[key] = val
        }
        return acc
      },
      {}
    )
  } else {
    console.error("idPropName is not a string")
    return {}
  }
}

export const formatDate = (dateString) => {
  const isDate = Object.prototype.toString.call(dateString) === "[object Date]"
  const date = isDate ? dateString : new Date(dateString)

  if (isNaN(date.getTime())) {
    return ""
  }

  const dateTimeFormat = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(date)
  return `${day}-${month}-${year}`
}

export const formatNumber = (number) => numberFormat(number, 2)

export const numberRequired = () =>
  yup.number().when("$disableFields", (disableFields, s) => {
    if (!disableFields) {
      return s.typeError("Must be a number").required("This field is required")
    }
    return s
  })
export const atLeastZero = (schema) => schema.min(0, "Min value is 0")

export const num = (number) => parseFloat(number) || 0
export const numFormat = (number) => numberFormat(number, 2)
