import React from "react"
import { numberFormat } from "utils/thousand-separator"

const numFormatForLwt = (number) => numberFormat(number, 1)

function DocumentInfo({ customerData, data, rateInCurr, rateOutCurr }) {
  return (
    <div style={{ display: "flex" }}>
      <div className="left-side" style={{ flexGrow: 1 }}>
        <table id="general" className="table-headleft fontSize">
          <tbody>
            <tr>
              <th>To:</th>
              <td>{customerData.name}</td>
            </tr>
            <tr>
              <th>Attn:</th>
              <td>{customerData.pic}</td>
            </tr>
            <tr>
              <th>Product:</th>
              <td>{data.productName}</td>
            </tr>
            <tr>
              <th>Project Type:</th>
              <td>{data.rfq?.processType?.processType || ""}</td>
            </tr>
            <tr>
              <th>Product Type:</th>
              <td>{data.rfq?.productType?.productType || ""}</td>
            </tr>
          </tbody>
        </table>

        <table id="lwt" className="table-headleft fontSize">
          <tbody>
            <tr>
              <th>LWT1:</th>
              <td>
                {numFormatForLwt(data.length)} x {numFormatForLwt(data.width)} x{" "}
                {numFormatForLwt(data.height)}
              </td>
            </tr>
            <tr>
              <th>LWT2:</th>
              <td>
                {numFormatForLwt(data.length2)} x {numFormatForLwt(data.width2)}{" "}
                x {numFormatForLwt(data.height2)}
              </td>
            </tr>
            <tr>
              <th>LWT3:</th>
              <td>
                {numFormatForLwt(data.length3)} x {numFormatForLwt(data.width3)}{" "}
                x {numFormatForLwt(data.height3)}
              </td>
            </tr>
            <tr>
              <th>LWT4:</th>
              <td>
                {numFormatForLwt(data.length4)} x {numFormatForLwt(data.width4)}{" "}
                x {numFormatForLwt(data.height4)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        className="right-side"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <table
          id="lwt"
          className="table-headleft fontSize"
          style={{
            marginBottom: "5mm",
          }}
        >
          <tbody>
            <tr>
              <th>Estimation Number:</th>
              <td>{data.estimationNumber}</td>
            </tr>
            <tr>
              <th>Estimation Date</th>
              <td>{data.estimationDate}</td>
            </tr>
            <tr>
              <th>Lead Time</th>
              <td>{data.leadTime} work days</td>
            </tr>
          </tbody>
        </table>

        <div style={{ display: "flex" }} className="fontSize">
          <p style={{ margin: "1mm 3mm" }}>Rate:</p>
          <table id="rate" className="table-headleft fontSize">
            <tbody>
              <tr>
                <th>{rateInCurr.code}</th>
                <td>{data.rateInValue}</td>
              </tr>
              <tr>
                <th>{rateOutCurr.code}</th>
                <td>{data.rateOutValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default DocumentInfo
